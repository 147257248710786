/* global.css */

@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --color-primary: #ff6f61; /* Vibrant red-orange */
    --color-secondary: #f7d154; /* Soft yellow */
    --color-accent: #73c2fb; /* Light blue */
    --color-background: #f4f4f4; /* Light gray */
    --color-text-primary: #333333; /* Dark gray */
    --color-text-secondary: #555555; /* Medium gray */
}

body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    transition: background-color 1.5s ease-out, color 1.5s ease-out;
}

h1,
h2,
h3,
h4,
h5,
h5 {
    margin-bottom:0;
}

.white {
  color:#fff;
}

.accent-col {
  background-color: var(--color-primary);
}
  
.draw-card-page {
  text-align: center;
  padding: 20px;
}

.draw-card-button {
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.draw-card-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  background-color: var(--color-secondary);
}

.card-display {
  margin-top: 20px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.card-display h2 {
  font-size: 24px;
  color: var(--color-primary);
  margin-bottom: 10px;
}

.card-display p {
  font-size: 18px;
  color: var(--color-text-primary);
}

@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
}

.background span {
  width: 20vmin;
  height: 20vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move infinite linear;
  animation-duration: 45s;
}


.background span:nth-child(0) {
  color:inherit;
  top: 42%;
  left: 59%;
  animation-duration: 35s;
  animation-delay: -26s;
  transform-origin: 10vw -11vh;
  box-shadow: 40vmin 0 5.514531661961495vmin currentColor;
}
.background span:nth-child(1) {
  color:inherit;
  top: 20%;
  left: 78%;
  animation-duration: 19s;
  animation-delay: -33s;
  transform-origin: 10vw 14vh;
  box-shadow: -40vmin 0 5.3956366837341525vmin currentColor;
}
.background span:nth-child(2) {
  color:inherit;
  top: 46%;
  left: 36%;
  animation-duration: 55s;
  animation-delay: -49s;
  transform-origin: 19vw -21vh;
  box-shadow: -40vmin 0 5.003248430600208vmin currentColor;
}
.background span:nth-child(3) {
  color:inherit;
  top: 72%;
  left: 36%;
  animation-duration: 18s;
  animation-delay: -36s;
  transform-origin: 4vw 20vh;
  box-shadow: 40vmin 0 5.078585552781899vmin currentColor;
}
.background span:nth-child(4) {
  color:inherit;
  top: 84%;
  left: 50%;
  animation-duration: 7s;
  animation-delay: -13s;
  transform-origin: 0vw -15vh;
  box-shadow: 40vmin 0 5.731198776599669vmin currentColor;
}
.background span:nth-child(5) {
  color:inherit;
  top: 33%;
  left: 76%;
  animation-duration: 39s;
  animation-delay: -26s;
  transform-origin: -12vw -4vh;
  box-shadow: 40vmin 0 5.227777526761211vmin currentColor;
}
.background span:nth-child(6) {
  color:inherit;
  top: 82%;
  left: 84%;
  animation-duration: 51s;
  animation-delay: -17s;
  transform-origin: -1vw 2vh;
  box-shadow: 40vmin 0 5.297646094774747vmin currentColor;
}
.background span:nth-child(7) {
  color:inherit;
  top: 20%;
  left: 36%;
  animation-duration: 46s;
  animation-delay: -8s;
  transform-origin: 2vw 13vh;
  box-shadow: 40vmin 0 5.710598172271323vmin currentColor;
}
.background span:nth-child(8) {
  color:inherit;
  top: 55%;
  left: 62%;
  animation-duration: 47s;
  animation-delay: -23s;
  transform-origin: 24vw 10vh;
  box-shadow: -40vmin 0 5.339122541234462vmin currentColor;
}
.background span:nth-child(9) {
  color:inherit;
  top: 32%;
  left: 52%;
  animation-duration: 33s;
  animation-delay: -39s;
  transform-origin: -15vw 25vh;
  box-shadow: -40vmin 0 5.967443135679946vmin currentColor;
}
.background span:nth-child(10) {
  color: #FFACAC;
  top: 19%;
  left: 18%;
  animation-duration: 49s;
  animation-delay: -37s;
  transform-origin: 18vw 19vh;
  box-shadow: -40vmin 0 5.496290621770614vmin currentColor;
}
.background span:nth-child(11) {
  color:inherit;
  top: 12%;
  left: 83%;
  animation-duration: 26s;
  animation-delay: -50s;
  transform-origin: 4vw -24vh;
  box-shadow: 40vmin 0 5.479930870999272vmin currentColor;
}
.background span:nth-child(12) {
  color:inherit;
  top: 30%;
  left: 90%;
  animation-duration: 47s;
  animation-delay: -21s;
  transform-origin: 4vw -12vh;
  box-shadow: 40vmin 0 5.917043003099102vmin currentColor;
}
.background span:nth-child(13) {
  color:inherit;
  top: 60%;
  left: 22%;
  animation-duration: 27s;
  animation-delay: -20s;
  transform-origin: 1vw 23vh;
  box-shadow: 40vmin 0 5.967726532264679vmin currentColor;
}
.background span:nth-child(14) {
  color:inherit;
  top: 1%;
  left: 63%;
  animation-duration: 29s;
  animation-delay: -32s;
  transform-origin: -11vw 2vh;
  box-shadow: 40vmin 0 5.681382587544919vmin currentColor;
}
.background span:nth-child(15) {
  color:inherit;
  top: 34%;
  left: 17%;
  animation-duration: 12s;
  animation-delay: -30s;
  transform-origin: -21vw 15vh;
  box-shadow: 40vmin 0 5.803185593331456vmin currentColor;
}
.background span:nth-child(16) {
  color:inherit;
  top: 10%;
  left: 74%;
  animation-duration: 29s;
  animation-delay: -45s;
  transform-origin: 2vw 14vh;
  box-shadow: 40vmin 0 5.955061524804873vmin currentColor;
}
.background span:nth-child(17) {
  color:inherit;
  top: 22%;
  left: 67%;
  animation-duration: 55s;
  animation-delay: -27s;
  transform-origin: -2vw -13vh;
  box-shadow: 40vmin 0 5.161698029826898vmin currentColor;
}
.background span:nth-child(18) {
  color:inherit;
  top: 20%;
  left: 61%;
  animation-duration: 42s;
  animation-delay: -12s;
  transform-origin: -20vw -24vh;
  box-shadow: -40vmin 0 5.098734027687238vmin currentColor;
}
.background span:nth-child(19) {
  color:inherit;
  top: 21%;
  left: 96%;
  animation-duration: 26s;
  animation-delay: -1s;
  transform-origin: -10vw -12vh;
  box-shadow: 40vmin 0 5.5013295370448025vmin currentColor;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}



